import { graphql } from 'gatsby';
import React from 'react';
import Hero from '../components/chapter/Hero';
import NextChapter from '../components/chapter/NextChapter';
import Text from '../components/chapter/Text';
import Layout from '../Layout';

const About = ({ data }) => {
  const { title, title_svg, texture, lede, components, color } =
    data.strapiAbout;
  return (
    <Layout title={title}>
      <Hero
        title={title}
        titleSvg={title_svg}
        texture={texture}
        lede={lede}
        color={color}
      />
      {components?.map(
        (
          {
            strapi_component,
            //attribution, TODO: implement more componence once data
            body,
            collage,
            //image_credit,
            //limit_height,
            //mask,
            //layout,
          },
          index
        ) => {
          return {
            'chapter.text': (
              <Text body={body} key={index} color={color} collage={collage} />
            ),
          }[strapi_component];
        }
      )}
      <NextChapter
        next={data.strapiChapters}
        color={{ accent: data.strapiChapters.color.accent, theme: color.theme }}
        btnText={'Explore'}
      />
    </Layout>
  );
};

export const query = graphql`
  {
    strapiAbout {
      title
      title_svg {
        localFile {
          svgData
        }
      }
      texture {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 60)
          }
        }
      }
      lede
      color {
        accent
        theme
      }
      components {
        strapi_component
        body
      }
    }
    strapiChapters(order: { eq: 310 }) {
      color {
        accent
      }
      preview_img {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 60)
          }
        }
      }
      slug
      title
      summary
    }
  }
`;

export default About;
